<template>
	<section class="popup" :class="{ 'popup_active': popupActive }" id="popup-staking" @click.self="closePopup">
		<div class="popup__container popup__container_with_header popup-staking--new">
			<div class="popup__header">
				{{ $t('staking.message.popupAttention') }}
			</div>
			<div class="popup__body">

				<template v-if="loadingExistingStaking">
					<div class="popup__body-loader">
						<spinner />
					</div>
				</template>

				<button type="button" class="popup__exit" @click="closePopup" />

				<h2 class="popup__title">
					{{ $t('staking.title.staking') }}
				</h2>

				<div class="popup__info">
					<p class="popup__info-title">
						{{ $t('staking.title.availableForStaking') }}
					</p>
					<p class="popup__info-price">
						{{ toFixedWithoutRounding(availableForStaking) }} <span
							class="popup__info-price-cur">DEXNET</span>
					</p>
				</div>

				<div class="popup__info">
					<p class="popup__info-title">
						{{ $t('staking.title.selectStakingType') }}
					</p>

					<ul class="popup__info-list">
						<li v-for="(termType, termTypeIndex) in stakingTerms" :key="`term_type_${termTypeIndex}`"
							class="popup__info-list-item" :class="{
								'popup__info-list-item_type_current': curTypeInd === termTypeIndex,
								'popup__info-list-item_type_disabled': !!address
							}" style="text-transform: uppercase;" @click="(curTypeInd = termTypeIndex, curTermInd = 0)">
							<!--              :class="{ 'popup__info-list-item_type_current': curTypeInd === termType.name }"-->
							<!--              @click="curTypeInd = termType.name"-->
							{{ termType.name }}
						</li>
					</ul>

					<div class="popup__info-select">
						<select name="#" class="popup__info-select-item" :disabled="!!address" v-model="curTypeInd"
							@change="curTermInd = 0">
							<option v-for="(termType, termTypeIndex) in stakingTerms"
								:key="`term_type_mob_${termTypeIndex}`" :value="termTypeIndex">
								{{ termType.name }}
							</option>
						</select>
					</div>
				</div>

				<div class="popup__info">
					<p class="popup__info-title">
						{{ $t('staking.title.selectPeriod') }}
					</p>

					<ul class="popup__info-list">
						<li v-for="(term, termIndex) in stakingTerms[curTypeInd].terms" :key="`term_${termIndex}`"
							class="popup__info-list-item" :class="{
								'popup__info-list-item_type_current': curTermInd === termIndex,
								'popup__info-list-item_type_disabled': !!address
							}" @click="curTermInd = termIndex">
							{{ term.period }} | {{ Number(term.percent) }}%
						</li>
					</ul>

					<div class="popup__info-select">
						<select name="#" class="popup__info-select-item" v-model="curTermInd" :disabled="!!address">
							<option v-for="(term, termIndex) in stakingTerms[curTypeInd].terms"
								:key="`term_mob_${termIndex}`" :value="termIndex">
								{{ term.period }} | {{ term.percent }}%
							</option>
						</select>
					</div>
				</div>

				<div class="popup__info popup__info_type_grid">
					<div class="popup__info-sum">
						<p class="popup__info-title">
							{{ $t('staking.title.stakingAmount') }}
						</p>
						<div class="popup__input-wrapper">
							<input
								v-model="stakingAmount"
								type="text"
								class="popup__input"
								:class="{ 'popup__input--error': tokensAmountError }"
								:disabled="!!address"
								@focus="isAmountInputFocused = true; isAmountInputTouched = true"
								@blur="isAmountInputFocused = false"
							>

							<div v-if="!allowExternalTokens && !address" class="popup__info-max" @click="setMax">
								MAX
							</div>

							<span v-if="tokensAmountError" class="popup__input-error">
								{{ $t('staking.title.amountError') }}
							</span>
						</div>
					</div>
					<div class="popup__info-apy">
						<p class="popup__info-title">
							{{ $t('staking.title.rewardAmount') }}
						</p>
						<p class="popup__info-price">
							{{ profitAmount }}
							<span class="popup__info-price-cur">DEXNET {{ $t('staking.title.token') }}</span>
						</p>
					</div>
				</div>

				<div class="popup__info popup__info_type_checkbox">
					<label
						class="form-checkbox"
						:class="{ 'form-checkbox--disabled': !!address }"
					>
						<input v-model="confirm" type="checkbox" class="form-checkbox__input">
						<p class="form-checkbox__text">
							{{ $t('staking.title.termsAgreement') }}
						</p>
					</label>
				</div>

				<button v-if="!address" class="popup__btn" @click="submitStaking">
					<spinner v-if="loading" style="color:black;" small />
					<template v-else>
						{{ $t('staking.title.confirmStaking') }}
					</template>
				</button>

				<div v-else class="staking-payment">
					<div class="staking-payment__qr">
						<canvas ref="qr_canvas" />
					</div>

					<div class="staking-payment__info">
						<div class="staking-payment__info-title">
							<div class="popup__info-price">
								{{ formatSum(+payAmount) }} DEXNET
							</div>

							<div class="popup__title">
								{{ formattedTime }}
								<span>
									{{ $t('Awaiting payment') }}
								</span>
							</div>
						</div>
						<p class="popup__info-title">
							{{ $t('Send the funds you want to stake to the address below.') }}<br>
							<b>{{ $t('Important: ') }}</b>{{ $t('For type 2 staking, only Dexnet purchased on the free market can be  used.') }}
						</p>

						<div class="popup-invoice__input-wrapper">
							<p class="popup-invoice__type">
								BEP-20
							</p>
							<label class="popup-invoice__label">
								<textarea class="popup-invoice__textarea" readonly="readonly">{{ address }}</textarea>
								<button class="popup-invoice__copy" type="button" aria-label="Скопировать код"
									@click="copyToClipboard(address)"></button>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import '@/assets/css/popup.css';
import QRCode from 'qrcode'

import { StakingError, StakingTermsType } from '@/interfaces/staking.type'

import { readBalances } from '@/store/main/getters'
import { api } from '@/api'

import Spinner from '@/components/Spinner.vue'

export default {
	components: {
		Spinner,
	},
	props: {
		stakingTerms: {
			type: Array,
			required: true,
		},
		createdStakingId: {
			type: Number,
			default: 0,
		},
	},
	data: () => ({
		popupActive: false,
		confirm: false,
		curTypeInd: 0,
		curTermInd: 0,
		loading: false,
		stakingAmount: '0',

		address: '',
		payAmount: '',
		formattedTime: '',

		loadingExistingStaking: false,

		isAmountInputFocused: false,
		isAmountInputTouched: false,
	}),
	computed: {
		balances() {
			return readBalances(this.$store)
		},
		availableForStaking() {
			return this.balances ? Number(this.balances.dxt_hold) + Number(this.balances.dxt) : 0
		},
		allowExternalTokens() {
			return this.stakingTerms[this.curTypeInd].allow_external_tokens
		},
		tokensAmountError() {
			return ((Number(this.stakingAmount) < 10 || Number(this.stakingAmount) > 1_000_000)) && this.isAmountInputTouched && !this.isAmountInputFocused
		},
		curTerm() {
			return this.stakingTerms[this.curTypeInd].terms[this.curTermInd]
		},
		profitAmount() {
			const amount = Number(this.stakingAmount)
			const percent = Number(this.curTerm.percent)
			const result = amount + (amount * percent / 100) * this.curTerm.period / 12
			return this.toFixedWithoutRounding(result)
		},
	},
	watch: {
		stakingAmount: {
			handler(value, oldValue) {
				console.debug('%c onChangeStakingAmount(val: %s, oldVal: %s)', 'color:gold;', value, oldValue)

				const amount = this.stakingAmount
					.replace(/[^0-9.]/g, '')
					.replace(/(.*\..*)\./, '$1')

				console.debug('%c amount: %s', 'color:lime;', amount)

				this.stakingAmount = amount
			}
		}
	},
	methods: {
		async submitStaking() {
			console.debug('%c submitStaking', 'color:gold;', this.confirm)
			if (this.loading) return

			if (!this.confirm) {
				this.$toast.warning('', {
					message: this.$t('staking.message.termsAgree').toString(),
					duration: 3000,
					position: 'bottom',
				})
				return
			}

			if (this.tokensAmountError) {
				return
			}

			try {
				this.loading = true

				let amount = Number(this.stakingAmount)

				if (this.toFixedWithoutRounding(Number(this.stakingAmount)) === this.toFixedWithoutRounding(this.availableForStaking)) {
					amount = this.availableForStaking
				}
				const { data, status } = await api.createStaking(this.$store.state.main.token, {
					staking_terms: this.curTerm.id,
					quantity: amount,
				})

				console.debug('%c popup/Staking.vue DATA: %s', 'color:lime;', JSON.stringify(data, null, 4))

				this.$emit('data-reload')

				if (data.status === 'not_paid') {
					this.displayStakingPayment(data)
				} else {
					this.closePopup()

					this.$toast.success('', {
						message: this.$t('staking.message.stakingCreated').toString(),
						duration: 7000,
						position: 'bottom',
					})
				}


			} catch (err) {
				console.debug('%c popup/Staking.vue submitStaking ERROR:', 'color:red;', err)

				console.debug('%c ******************************************** err:', 'color:orangered;')
				console.debug(err)
				console.debug('%c ******************************************** err.response:', 'color:orangered;')
				console.debug(err.response)
				console.debug('%c ********************************************', 'color:orangered;')

				const errMsg = err.response?.data?.error
				console.debug('%c err.response.data.error: %s', 'color:orangered;', errMsg)

				if (errMsg && errMsg in StakingError) {
					this.$toast.error('', {
						message: this.$t(`staking.error.${errMsg}`).toString(),
						duration: 7000,
						position: 'bottom',
					})
				}
			}
			this.loading = false
		},

		toFixedWithoutRounding(n) {
			return n.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
		},


		formatSum(number) {
			return new Intl.NumberFormat('en-US', {
				style: 'decimal',
				minimumFractionDigits: 0,
				maximumFractionDigits: 5,
			}).format(number)
		},

		copyToClipboard(value) {
			// const toast = useToast();
			this.$copyText(value.toString()).then((e) => {
				// @ts-ignore
				this.$toast.success(this.$t('Copied successfully'), {
					duration: 2000, position: 'bottom',
				});
			}, (e) => {
				// @ts-ignore
				this.$toast.error(this.$t('Error, unable to copy'), {
					duration: 2000, position: 'bottom',
				});
			});
		},

		setMax() {
			this.stakingAmount = this.toFixedWithoutRounding(this.availableForStaking)
		},

		closePopup() {
			this.popupActive = false
			setTimeout(() => {
				this.$emit('close')
			}, 1000)
		},

		getStakingStatus(id) {
			try {
				const { data } = api.getStakingStatus(this.$store.state.main.token, id)
				return data
			} catch (err) {
				console.debug('%c popup/Staking.vue getStakingStatus ERROR:', 'color:red;', err)
			}
		},

		async displayStakingPayment(data) {
			this.loadingExistingStaking = true

			let stakingData = data || null

			if (!stakingData) {
				try {
					const { data } = await api.getStakingStatus(this.$store.state.main.token, this.createdStakingId)
					stakingData = data
				} catch (err) {
					console.debug('%c popup/Staking.vue displayStakingPayment ERROR:', 'color:red;', err)

					this.$emit('data-reload')
					this.closePopup()
				}
			}

			this.stakingAmount = +stakingData.quantity
			this.confirm = true
			this.curTypeInd = this.stakingTerms.findIndex(({ name }) => name === stakingData.staking_type)
			this.curTermInd = this.stakingTerms[this.curTypeInd].terms.findIndex(({ period }) => period === stakingData.period)

			this.address = stakingData.address
			this.payAmount = stakingData.quantity

			await this.$nextTick();

			QRCode.toCanvas(this.$refs.qr_canvas, stakingData.address, function (error) {
				if (error) console.error(error)
				console.log('success!');
			})

			const end = new Date(stakingData.created_at).getTime() - (new Date().getTimezoneOffset() * 60000) + (15 * 60 * 1000)
			const statusInterval = setInterval(async () => {
				const { data: response } = await api.getStakingStatus(this.$store.state.main.token, stakingData.id)
				// @ts-ignore
				if (response.status === 'waiting') {
					clearInterval(statusInterval)
					clearInterval(timeInterval)

					this.$emit('data-reload')
					this.closePopup()
				}
			}, 5000)

			const timeInterval = setInterval(() => {
				let current = new Date().getTime()
				let distance = end - current

				let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
				let seconds = Math.floor((distance % (1000 * 60)) / 1000)

				this.formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

				if (current >= end) {
					clearInterval(timeInterval)
					clearInterval(statusInterval)

					this.$toast.success('', {
						message: this.$t('The expiration date has expired.').toString(),
						duration: 7000,
						position: 'bottom',
					})

					this.$emit('data-reload')
					this.closePopup()
				}
			}, 1000)

			this.loadingExistingStaking = false
		}
	},
	mounted() {
		setTimeout(() => {
			this.popupActive = true
		}, 10)

		console.log('CREATED STAKING ID:', this.createdStakingId)
		if (this.createdStakingId) {
			this.displayStakingPayment()
		}
	}
}
</script>

<style lang="scss">
.form-checkbox--disabled {
	opacity: 0.5;
	pointer-events: none;
}

.form-checkbox--disabled * {
	pointer-events: none;
}

.popup__body {
	position: relative;
}

.popup__body-loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #202020;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 99;
}

.staking-payment {
	margin-top: 24px;
	display: flex;
	align-items: stretch;

	&__qr {
		width: 100%;
		min-width: 148px;
		min-height: 148px;
		max-width: 148px;
		max-height: 148px;

		margin-right: 12px;

		& canvas {
			background: #ffffff;
			border-radius: 12px;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;

		& .popup__info-title {
			font-size: 14px;
		}

		&-title {
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 12px;

			& .popup__info-price,
			& .popup__title {
				font-size: 16px !important;
				margin: 0 !important;
			}

			& .popup__info-price {
				color: #00fff0;
			}

			& .popup__title {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
			}

			& .popup__title span {
				display: inline-block;
				font-size: 12px;
				color: #fff;
			}
		}
	}

	& .popup-invoice__input-wrapper {
		padding: 4px !important;
	}

	& .popup-invoice__type {
		padding: 4px !important;
	}
}

.popup-invoice__input-wrapper {
	border: 1px solid #333;
	padding: 8px 12px 8px 8px;
	background: #212121;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 12px;
	column-gap: 12px;
}

.popup-invoice__type {
	background: #2f2f2f;
	border: 1px solid #333;
	padding: 8px 12px 8px 8px;
	font-size: 12px;
	line-height: 133%;
	color: #fff;
	font-family: "Inter", sans-serif;
	display: flex;
	align-items: center;
	column-gap: 4px;
	flex-shrink: 0;
}

.popup-invoice__type:before {
	content: "";
	display: block;
	width: 20px;
	height: 20px;
	background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.89547 6.72254L8.00325 3.61647L11.1123 6.72507L12.9196 4.91677L8.00325 -0.00012207L3.08691 4.91551L4.89547 6.72254ZM0 7.99988L1.8079 6.19224L3.61579 7.99988L1.8079 9.80751L0 7.99988ZM8.00578 12.3848L4.898 9.27751L3.08691 11.0833L3.08944 11.0858L8.00578 16.0002L12.9221 11.0833L12.9234 11.082L11.1148 9.27625L8.00578 12.3848ZM12.3838 7.99988L14.1917 6.19224L15.9996 7.99988L14.1917 9.80751L12.3838 7.99988ZM8.00117 6.16492L9.83501 7.99975H9.83627L9.83501 8.00101L8.00117 9.83584L6.16734 8.00354L6.16481 7.99975L6.16734 7.99722L6.48836 7.67625L6.64507 7.52082L8.00117 6.16492Z' fill='white' /%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	flex-shrink: 0;
}

.popup-invoice__label {
	display: flex;
	align-items: center;
	width: 100%;
	column-gap: 12px;
}

.popup-invoice__textarea {
	display: block;
	width: 100%;
	background: transparent;
	appearance: none;
	font-size: 12px;
	line-height: 133%;
	color: #fff;
}

.popup-invoice__copy {
	width: 24px;
	height: 24px;
	display: block;
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 22L9 9L22 9.00846L22 22L9 22Z' stroke='%2300FFF0' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M17 9V2.00977L2 2L2 17H9' stroke='%2300FFF0' stroke-width='1.5' stroke-linejoin='round' /%3E%3C/svg%3E");
	background-position: center;
	background-size: contain;
	flex-shrink: 0;
	transition: 0.3s ease-in-out;
}

@media (hover: hover) {
	.popup-invoice__copy:hover {
		opacity: 0.7;
	}
}

@media (hover: none) {
	.popup-invoice__copy:active {
		opacity: 0.7;
	}
}
</style>
