export type StakingRegisterType = {
  id: number,
  staking_type: string,
  quantity: string,
  profit: string,
  period: number,
  percent: string,
  pay_type: string,
  pay_dt: string,
  end_dt: string,
  status: string,
  created_at: string,
}

export type StakingTermType = {
  id: number,
  period: number,
  percent: string,
  pay_type: string,
}

export type StakingTermsType = {
  allow_external_tokens: any
  name: string,
  currency: string,
  terms: StakingTermType[],
}

export enum TransactionStatus {
  NONE = '',
  SUCCESS = 'success',
  PROCESSING = 'processing',
  CANCEL = 'cancel',
  ERROR = 'error',
}

export enum StakingError {
  InvalidStakingTerms = 'InvalidStakingTerms',
  InvalidQuantity = 'InvalidQuantity',
  InsufficientFunds = 'InsufficientFunds',
  StakingBlockIsEmpty = 'StakingBlockIsEmpty',
}

export enum WithdrawalError {
  MinAmountLimit = 'MinAmountLimit',
  MaxAmountLimit = 'MaxAmountLimit',
  InsufficientFunds = 'InsufficientFunds',
  ErrorGettingBalance = 'ErrorGettingBalance',
  MainWalletIsNotSet = 'MainWalletIsNotSet',
  MainWalletLimit24h = 'MainWalletLimit24h',
}

export enum RegisterType {
  ARRIVAL = 'arrival',
  EXPENSE = 'expense',
}

export type TransactionResultType = {
  id: number,
  comment: string,
  comment_type: string,
  comment_icon: string,
  created_ts: number,
  updated_ts: number,
  obj_type: string,
  obj_id: string,
  register_type: RegisterType,
  comment_template: string,
  comment_params: {
    quantity?: number,
    device_number?: string,

    address?: string,
    amount?: string,
    confirmations?: string,
    currency?: string,
    fee?: string,
    standart?: string,
    token?: string,
    txid?: string,
    txid_url?: string,
  },
  confirmed: boolean,
  processed: boolean,
  status: string,
  // currency: string, // Удалено на беке 06.06.2024
  sum: string,
  rate: string,
  remain_balance: string,
}

export type TransactionHistoryType = {
  next: number | null,
  previous: number | null,
  current: number,
  count: number,
  results: TransactionResultType[],
}
