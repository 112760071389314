
import { Component, Vue } from 'vue-property-decorator'
import FinTransactions from '@/components/fin/Transactions.vue'
import FinStaking from '@/components/fin/Staking.vue'
import { api } from '@/api'
import {
  StakingRegisterType,
  StakingTermsType,
  TransactionHistoryType,
} from '@/interfaces/staking.type'
import { dispatchLogOut, dispatchSetBalances } from '@/store/main/actions'
import PopupWithdrawal from '@/components/popup/Withdrawal.vue'
// import PopupStaking from '@/components/popup/Staking.vue'
import PopupStaking from '@/components/popup/StakingNew.vue'
import DepositModal from '@/components/Main/DepositModal.vue'
import { WalletType, WithdrawalLimitsType } from '@/interfaces/wallet.type'

@Component({
  components: {
    PopupStaking,
    FinTransactions,
    FinStaking,
    PopupWithdrawal,
    DepositModal,
  },
})
export default class Finance extends Vue {
  public tab: number = 0

  public loading: boolean = false
  public history: TransactionHistoryType | null = null
  public stakingTerms: StakingTermsType[] = []
  public stakingRegisters: StakingRegisterType[] = []
  public showWithdrawalPopup: boolean = false

  public wallet: WalletType | null = null
  public limits: WithdrawalLimitsType | null = null

  public showDepositModal: boolean = false

  async gotoPage(page: number) {
    console.debug('%c Finance.vue gotoPage(%s)', 'color:magenta;', page)

    this.loading = true
    try {
      await this.getPaymentHistory(page)

    } catch (err) {
      console.debug('%c Finance.vue gotoPage ERROR:', 'color:red;', err)
    }

    this.loading = false
  }

  async getPaymentHistory(page = 1) {
    try {
      const { data } = await api.getPaymentsHistory(this.$store.state.main.token, page)
      if (data) this.history = data
    } catch (err) {
      console.debug('%c Finance.vue getHistory ERROR:', 'color:red;', err)
    }
  }

  async getStakingTerms() {
    const result = await api.getStakingTerms(this.$store.state.main.token)
    if (result.data) this.stakingTerms = result.data

    // try {
    //   const { data } = await api.getStakingTerms(this.$store.state.main.token)
    //   if (data) this.stakingTerms = data
    // } catch (err) {
    //   console.debug('%c Finance.vue getStakingTerms ERROR:', 'color:red;', err)
    // }
  }

  async getStakingRegisters() {
    try {
      const { data } = await api.getStakingRegisters(this.$store.state.main.token)
      if (data) this.stakingRegisters = data
    } catch (err) {
      console.debug('%c Finance.vue getStakingRegisters ERROR:', 'color:red;', err)
    }
  }

  requestWithdrawal() {
    // console.debug('%c Finance.vue requestWithdrawal', 'color:lime;', this.$route, location)

    // if (location.hostname === 'dexnet.one') {
    if (!this.wallet?.withdraw_available) {
      // Отображение попапа вывода для прода отключено
      this.$toast.warning('', {
        message: String(this.$t('staking.message.availableIn24Hours')),
        duration: 7000,
      })
    } else {
      this.showWithdrawalPopup = true
    }
  }

  switchTab(tab: number) {
    this.tab = tab
  }

  async dataReload() {
    console.debug('%c Finance.vue dataReload', 'color:magenta;')
    this.getPaymentHistory()
    this.getStakingTerms()
    this.getStakingRegisters()

    try {
      const { data } = await api.getBalances(this.$store.state.main.token)
      // console.debug('%c getBalances: %s', 'color:lime;', JSON.stringify(data, null, 4))
      if (data) dispatchSetBalances(this.$store, data)
    } catch (err) {
      console.debug('%c Finance.vue getBalances ERROR:', 'color:red;', err)
      // Разлогиниться, если от бека пришла ошибка
      await dispatchLogOut(this.$store);
    }
  }

  async getWallet() {
    const result = await api.getWithdrawalWallet(this.$store.state.main.token)

    if (result.data) {
      this.wallet = result.data as WalletType //.address
      // console.debug('%c Finance.vue getWallet() result.data: %s', 'color:lime;', JSON.stringify(result.data, null, 4))
    }
  }

  async getLimits() {
    // console.debug('%c getLimits()', 'color:gold;font-size:36px;')
    const result = await api.getWithdrawalLimits(this.$store.state.main.token)
    // console.debug('%c getLimits() RESULT:', 'color:lime;', result)
    if (result.data) {
      this.limits = result.data as WithdrawalLimitsType
      // console.debug('%c Finance.vue getLimits() result.data: %s', 'color:lime;', JSON.stringify(result.data, null, 4))
    }
  }

  setWallet(wallet: WalletType) {
    console.debug('%c Finance.vue setWallet(%s)', 'color:lime;', JSON.stringify(wallet))
    this.wallet = wallet
  }

  async mounted() {
    this.dataReload()
    this.getWallet()
    this.getLimits()
  }
}

